import { lazy } from 'react';
import { Loadable } from './components/Loadable';
import { createTheme, ThemeProvider } from '@mui/material';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RouterComponent = Loadable(
  lazy(() => import('./components/RouterComponent'))
);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#000' },
    secondary: { main: '#000' },
    black: { main: '#000' },
    text: {
      primary: '#000',
      secondary: '#000',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
    },
  },
  typography: {
    h1: { fontSize: '5rem' },
    h2: { fontSize: '2.5rem' },
    h5: { fontSize: '1rem' },
    h6: { fontSize: '0.7rem' },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <RouterComponent />
    </ThemeProvider>
  );
}

export default App;
